<template>
  <div>
    <div class="l-modal">
      <div class="l-modal_cont">
        <div class="l-memberStatusChange">
          <p class="l-memberStatusChange_txt">以下のステータスに変更します</p>

          <div class="l-memberStatusChange_status">
            <p
              class="c-memberStatus"
              :class="`${before.class} c-memberStatus-large`"
            >
              {{ before.text }}
            </p>
            <img
              class="l-memberStatusChange_status_arrow"
              src="@/assets/img/arrow-black.svg"
            />
            <p
              class="c-memberStatus"
              :class="`${after.class} c-memberStatus-large`"
            >
              {{ after.text }}
            </p>
          </div>

          <div
            class="c-btnMedium l-memberStatusChange_btnMedium"
            @click="submit"
          >
            <p class="c-btnMedium_txt" v-if="isActive">変更する</p>
            <p class="c-btnMedium_txt" v-else>変更中</p>
          </div>
          <router-link to="/member_top" class="l-memberStatusChange_btnSmall">
            <p class="">HOMEへ戻る</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MemberStatusChange",
  data: () => ({
    id: -1,
    before: {},
    after: {},
    isActive: true,
  }),
  mounted() {
    this.id = Number(this.$route.query.id);
    this.before = this.convert(Number(this.$route.query.before));
    this.after = this.convert(Number(this.$route.query.after));
  },
  methods: {
    convert(t) {
      switch (t) {
        case 0:
          return {
            type: 0,
            text: "不在",
            class: "c-memberStatus-absence",
          };
        case 1:
          return {
            type: 1,
            text: "在席中",
            class: "c-memberStatus-attendance",
          };
        case 2:
          return {
            type: 2,
            text: "来客中",
            class: "c-memberStatus-withVisitor",
          };
      }
    },
    submit() {
      if (!this.isActive) return;

      this.isActive = false;

      axios.post("/update.php", {
        id: localStorage.getItem("me"),
        member: this.id,
        status: this.after.type,
      });

      setTimeout(function() {
        this.$router.push("/member_top")
      }.bind(this), 500);
    },
  },
};
</script>

<style scoped lang="scss">
.l-memberStatusChange {
  padding: 80px 30px 25px;

  &_txt {
    text-align: center;
    line-height: 1;
    font-size: 18px;
  }

  &_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 90px;

    &_arrow {
      width: 31px;
      height: 15px;
    }
  }

  &_btnMedium {
    margin-top: 64px;
  }

  &_btnSmall {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px auto 0;
    border: 1px solid $black;
    border-radius: 11px;
    width: 187px;
    height: 60px;
  }
}

.c-memberStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  padding-bottom: 1px;
  width: 75px;
  height: 25px;
  line-height: 1;
  font-size: 14px;
  color: $white;

  &-attendance {
    background-color: $blue;
  }

  &-absence {
    background-color: $gray;
  }

  &-withVisitor {
    background-color: $orange;
  }

  &-large {
    border-radius: 29px;
    width: 134px;
    height: 44px;
    font-size: 18px;
  }
}
</style>
